import { Form } from "../../../../components/Form";
import * as S from './styled';

export function ContactUs() {
  return (
    <S.FormContainer id="agendamento-consultas">
      <S.FormTitle>Fale conosco</S.FormTitle>
      <Form />
    </S.FormContainer>
  )
}
