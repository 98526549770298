import { styled } from "../../../../theme/Provider"

export const FormContainer = styled.div`
    width: 40%;
    box-sizing: border-box;
    padding: ${({ theme: { form }}) => form.padding };
    border-radius: ${({ theme: { form }}) => form.borderRadius };
    margin-right: 3.875rem;
    background-color: ${({ theme: { form }}) => form.background };
    margin-top: -95px;
    z-index: 2;

  @media (max-width: 960px) {
    width: 90%;
    margin: 32px auto 0 auto;
  }
`

export const FormTitle = styled.h1`
  margin-bottom: 1.5rem;
  color: ${({ theme: { form }}) => form.title.color};
  font: ${({ theme: { form }}) => form.title.font};
`
