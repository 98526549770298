import iconeEstetoscopio from '../../../../assets/img/icone-estetoscopio.svg'
import iconeMedico from '../../../../assets/img/icone-medico.svg'
import iconePipetas from '../../../../assets/img/icone-pipetas.svg'
import iconeMedicamento from '../../../../assets/img/icone-medicamentos.svg'
import DiferencialContainer from './DiferencialComponent'
import * as S from './styled'

export default function Diferenciais () {
  return <S.Container>
    <S.Header>Diferenciais</S.Header>
    <S.GridDiferenciais>
      <DiferencialContainer image={iconeEstetoscopio}>
        <p>Consultas médicas com preço fixo de R$ 70</p>
      </DiferencialContainer>

      <DiferencialContainer image={iconeMedico}>
        <p>53 áreas médicas atendidas, entre elas cardiologista, ortopedista, ginecologista, oftalmologista e pediatra</p>
      </DiferencialContainer>

      <DiferencialContainer image={iconePipetas}>
        <p>Descontos de até 20% em exames laboratoriais e de imagem</p>
      </DiferencialContainer>

      <DiferencialContainer image={iconeMedicamento}>
        <p>Descontos de até 35% nas farmácias DrogaRaia e Drogasil</p>
      </DiferencialContainer>
    </S.GridDiferenciais>
  </S.Container>
}
