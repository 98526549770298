import { ITheme, styled } from '../../theme/Provider';

const getStyle = ({ theme: { button }, ...props }: { theme: ITheme, variant: "primary" | "secondary" }) =>
  {
    if (props.variant === "secondary") {
      return {
        padding: button.secondary.padding,
        "border-radius": button.secondary.borderRadius,
        "background-color": button.secondary.background.default,
        color: button.secondary.color.default,
        font: button.secondary.font,
        "@media(max-width: 481px)": {
          padding: button.secondary.paddingMobile,
          font: button.primary.fontMobile,

        }
      }
    }
    return {
      padding: button.primary.padding,
      "border-radius": button.primary.borderRadius,
      "background-color": button.primary.background.default,
      color: button.primary.color.default,
      font: button.primary.font,
      "@media(max-width: 481px)": {
        padding: button.primary.paddingMobile,
        font: button.primary.fontMobile,
      }

    }
  }

export const Button = styled.button`
border: none;
cursor: pointer;
${getStyle}
white-space: nowrap;
`;
