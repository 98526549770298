import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 112px;
  margin-bottom: 124px;
  padding: 0 3.875rem;

  h2 {
    color: #00987B;
    font-family: 'Ubuntu';
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 37px;
    margin-bottom: 32px;
  }

  p {
    color: #1D1D1D;
    font-family: 'Ubuntu';
    line-height: 28px;
    font-size: 20px;
    font-style: normal;
  }

  strong {
    font-weight: 700;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 0;
    margin-bottom: 41px;
  }
`

export const Content = styled.div`
  margin-top: 55px;
  max-width: 426px;
  @media (max-width: 1024px) {
    flex: 1;
    margin-bottom: 32px;
    max-width: 100%;
  }
`

export const Image = styled.img`
  flex: 1;
  width: 50%;
  max-width: 100%;
  height: auto;

  @media (max-width: 1366px) {
    max-width: 50%;
  }

  @media (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
  }
`

