import logoAmorSaude from '../../../../assets/img/logo-amorsaude.png'
import boneco from '../../../../assets/img/boneco.png'
import * as S from './styled'

export default function NossasClinicas () {
  return <S.Container id="nossas-clinicas">
    <div>
      <S.LogoAmorSaude src={logoAmorSaude} />
      <S.Title>Rede de clínicas AmorSaúde<br /><small>Consultas Médicas e Exames em um só lugar.</small></S.Title>
      <S.Text>Nossas unidades oferecem um ambiente confortável, atendimento rápido e humanizado com qualidade e segurança.</S.Text>
      <S.Content>
        <S.Holder>
          <p><strong>Nossa estrutura:</strong></p>
          <ul>
            <li>Mais de 400 clínicas</li>
            <li>Cobertura em todo o Brasil</li>
            <li>Atendimento a 53 áreas médicas</li>
            <li>+ de 12.000 médicos</li>
            <li>4.000 tipos de exames</li>
          </ul>
        </S.Holder>
        <S.Holder>
          <p><strong>Principais áreas de atuação</strong></p>
          <ul>
            <li>Clínica Geral</li>
            <li>Cardiologia</li>
            <li>Ortopedia</li>
            <li>Ginecologia</li>
            <li>Pediatria</li>
            <li>Oftalmologia</li>
          </ul>
        </S.Holder>
      </S.Content>
      <S.HolderLarge>
        <p><strong>Parceria com os laboratórios:</strong></p>
        <ul>
          <li>Hermes Pardini</li>
          <li>Alvaro</li>
          <li>DB Diagnósticos</li>
        </ul>
      </S.HolderLarge>
    </div>

    <S.Button href="https://amorsaude.com.br/unidades/" target="_blank">Buscar clínica</S.Button>

    <S.Boneco>
      <img src={boneco} alt="Mascote" />
    </S.Boneco>
  </S.Container>
}
