import styled from "styled-components"

export const Container = styled.div`

  gap: 112px;
  margin-bottom: 124px;
  padding: 0 3.875rem;
  position: relative;

  hr {
  background: #C8C6C7;
  margin-bottom: 60px;
  max-width: 1242px;
  opacity: 0.5;
  }

  h2 {
    color: #00987B;
    font-family: 'Ubuntu';
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 37px;
    margin-bottom: 32px;
    text-align: center;
  }

  .content-faq {
    display: flex;
  }

  .content-faq .col-6 {
    gap: 10px;
    width: 50%;
    margin-left: 15px;
    margin-right: 15px;
  }

  .accordion {
    border: none;
  }

  .accordion__item {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    margin-bottom: 24px;
  }

  .accordion__button {
    background: #fff!important;
    width: auto!important;
    position: relative!important;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #00A988;
    padding: 16px;
    border-radius: 8px;
  }

  .accordion__button::before {
    position: absolute;
    right: 16px;
    border-color: #00A988;
    height: 8px;
    width: 8px;
  }

  .accordion__button svg {
    margin-top: 4px;
    margin-right: 8px;
  }

  .accordion__panel {
    padding: 0 20px 20px;
  }

  p {
    color: #000;
    line-height: 24px;
    font-size: 16px;
    font-weigth: 400;
  }

  a {
    color: #00A988;
  }

  strong {
    font-weight: 700;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 0;
    padding: 0 20px;
    margin-bottom: 41px;

    hr {
      position: absolute;
      top: -45px;
      left: 0;
      width: 100%;
    }

    h2 {
      margin-top: 30px
    }

    .content-faq {
      display: flex;
      flex-direction: column;
    }

    .content-faq .col-6 {
      gap: 10px;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    .accordion__button::before {
      right: 0px;
    }
  }
`
