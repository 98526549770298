import { getThemeFont } from './helpers';

const colors = {
  white: '#ffffff',
  greenSea: '#00987b',
  greenLime: '#a6ff00',
  paleGreenLime: '#a1c518',
  blueSky: '#57d6d9',
  neonBlue: '#309098',
  redError: '#ff0000',
  lightGray: '#c8c6c7',
  gray: '#38393a',
  dark: '#1d1d1d',
} as const;

const fontSizes = {
  xxxl: '3.875rem',
  xxl: '2.5rem',
  xl: '2rem',
  l: '1.5rem',
  m: '1.25rem',
  s: '1.25rem',
  ss: '1.125rem',
  xs: '1rem',
  xxs: '0.75rem',
} as const;

const lineHeights = {
  xxxl: '3.875rem',
  xxl: '3rem',
  xl: '2.25rem',
  l: '2.5rem',
  m: '2rem',
  s: '1.75rem',
  ss: '1.625rem',
  xs: '1.5rem',
  xxs: '1.25rem',
} as const;

const root = {
  colors: {
    primary: colors.greenSea,
    secondary: colors.white,
    accent: colors.greenLime,
    shadow: `${colors.dark}25`,
    error: colors.redError,
    backgroundPrimary: colors.white,
    backgroundSecondary: colors.greenSea,
    txtPrimary: colors.greenSea,
    txtSecondary: colors.white,
    txtParagraph: colors.dark,
    specialBgSectionGray: colors.lightGray,
    specialBgSectionBlue: colors.blueSky,
    inputTxtPlaceholder: colors.gray,
    inputBorder: colors.neonBlue,
    boxWithIconBorder: colors.paleGreenLime,
  },
  fontFamily: 'Ubuntu',
  fontSizes,
  lineHeights,
  fontWeights: {
    bold: 700,
    normal: 400,
    light: 300,
  },
};

export const softSodexoTheme = {
  main: {
    paddingDesktop: '3.875rem',
    paddingMobile: '1.5rem'
  },
  header: {
    color: root.colors.txtParagraph,
    font: `normal normal ${getThemeFont(
      { weight: 'bold', fontSize: 'xs', lineHeight: 'l' },
      root,
    )}`,
    background: root.colors.secondary,
    padding: '1rem 0.875rem 1rem 2rem',
    paddingMobile: '1.25rem',
    borderRadius: '0.5rem',
  },
  hero: {
    title: {
      color: root.colors.txtSecondary,
      font: `normal normal ${getThemeFont(
        { weight: 'normal', fontSize: 'xxl', lineHeight: 'xxl' },
        root,
      )}`,
      fontMobile: `normal normal ${getThemeFont(
        { weight: 'normal', fontSize: 'xs', lineHeight: 'xs' },
        root,
      )}`,
    },
    subtitle: {
      color: root.colors.txtSecondary,
      font: `normal normal ${getThemeFont(
        { weight: 'normal', fontSize: 'l', lineHeight: 'l' },
        root,
      )}`,
      fontMobile: `normal normal ${getThemeFont(
        { weight: 'normal', fontSize: 's', lineHeight: 's' },
        root,
      )}`,

    },
    imgMask: root.colors.accent,
    specialBg: root.colors.specialBgSectionGray,
  },
  button: {
    primary: {
      font: `normal normal ${getThemeFont(
        { weight: 'bold', fontSize: 'm', lineHeight: 'm' },
        root,
      )}`,
      fontMobile: `normal normal ${getThemeFont(
        { weight: 'bold', fontSize: 'xs', lineHeight: 'xxs' },
        root,
      )}`,
      borderRadius: '0.5rem',
      background: {
        default: root.colors.backgroundPrimary,
        hover: root.colors.backgroundSecondary,
      },
      color: {
        default: root.colors.txtPrimary,
        hover: root.colors.txtSecondary,
      },
      padding: '1rem 2rem',
      paddingMobile: '0.5rem 1.5rem',
    },
    secondary: {
      font: `normal normal ${getThemeFont(
        { weight: 'bold', fontSize: 'm', lineHeight: 'm' },
        root,
      )}`,
      fontMobile: `normal normal ${getThemeFont(
        { weight: 'bold', fontSize: 'xs', lineHeight: 'xxs' },
        root,
      )}`,
      borderRadius: '0.5rem',
      background: {
        default: root.colors.backgroundSecondary,
        hover: root.colors.backgroundSecondary,
      },
      color: {
        default: root.colors.txtSecondary,
        hover: root.colors.txtSecondary,
      },
      padding: '0.5rem 2rem',
      paddingMobile: '0.5rem 1.5rem',
    },
  },
  boxes: {
    boxWithIcon: {
      border: `1px solid ${root.colors.boxWithIconBorder}`,
      borderRadius: '2.5rem',
      font: `normal normal ${getThemeFont(
        { weight: 'normal', fontSize: 'xs', lineHeight: 'xs' },
        root,
      )}`,
      color: root.colors.txtParagraph,
    },
    boxInfoNumber: {
      border: `2px solid ${root.colors.secondary}`,
      borderRadius: '2.5rem',
      font: `normal normal ${getThemeFont(
        { weight: 'normal', fontSize: 'xs', lineHeight: 'xs' },
        root,
      )}`,
      emphasizeFont: `normal normal ${getThemeFont(
        { weight: 'bold', fontSize: 'xxxl', lineHeight: 'xxxl' },
        root,
      )}`,
      color: root.colors.txtSecondary,
      padding: '1.375rem 4.375rem',
    },
    boxInfoList: {
      border: `2px solid ${root.colors.secondary}`,
      borderRadius: '2.5rem',
      font: `normal normal ${getThemeFont(
        { weight: 'normal', fontSize: 'ss', lineHeight: 'ss' },
        root,
      )}`,
      color: root.colors.txtSecondary,
      padding: '2.875rem 1rem',
    },
    boxFooter: {
      border: `2px solid ${root.colors.primary}`,
      borderRadius: '2.5rem',
      font: `normal normal ${getThemeFont(
        { weight: 'normal', fontSize: 'l', lineHeight: 'xs' },
        root,
      )}`,
      color: root.colors.primary,
      label: {
        font: `normal normal ${getThemeFont(
          { weight: 'normal', fontSize: 'xs', lineHeight: 'ss' },
          root,
        )}`,
        color: root.colors.txtParagraph,
      },
    },
  },
  form: {
    title: {
      color: root.colors.secondary,
      font: `normal normal ${getThemeFont(
        { weight: 'bold', fontSize: 'l', lineHeight: 'm' },
        root,
      )}`,
    },
    padding: '5rem 3.75rem',
    borderRadius: '2.5rem',
    gapRow: '1.5rem',
    gapColumn: '1rem',
    background: root.colors.primary,
  },
  input: {
    label: {
      color: root.colors.secondary,
      font: `normal normal ${getThemeFont(
        { weight: 'bold', fontSize: 'xxs', lineHeight: 'm' },
        root,
      )}`,
    },
    placeholder: {
      color: root.colors.inputTxtPlaceholder,
      font: `italic normal ${getThemeFont(
        { weight: 'light', fontSize: 'xxs', lineHeight: 'xs' },
        root,
      )}`,
    },
    error: {
      background: root.colors.secondary,
      color: root.colors.error,
    },
    border: `1px solid ${root.colors.inputBorder}`,
    borderRadius: "0.625rem",
    background: root.colors.secondary,
    color: root.colors.txtParagraph,
    font: `normal normal ${getThemeFont(
      { weight: 'bold', fontSize: 'xxs', lineHeight: 'xs' },
      root,
    )}`
  },
  schedule: {
    title: {
      color: root.colors.txtParagraph,
      font: `normal normal ${getThemeFont(
        { weight: 'bold', fontSize: 'xl', lineHeight: 'm' },
        root,
      )}`
    },
    subtitle: {
      color: root.colors.txtParagraph,
      font: `normal normal ${getThemeFont(
        { weight: 'normal', fontSize: 'm', lineHeight: 'm' },
        root,
      )}`
    }
  },
  root,
};
