import styled from 'styled-components'

export const Container = styled.footer`
  align-items: center;
  border-top: 1px solid #C8C6C7;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 1242px;
  padding: 62px 99px 0 99px;

  @media (max-width: 768px) {
    padding: 62px 0 0 0;
  }
`

export const Contact = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  gap: 62px;
  justify-content: space-around;
  margin-bottom: 62px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 1301px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

export const SoftLogo = styled.img``

export const Agendamento = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

export const AgendamentoSite = styled.div`
text-align: center;

a {
  align-items: center;
  border: 2px solid #00987B;
  border-radius: 31px;
  color: #00987B;
  display: flex;
  height: 62px;
  justify-content: center;
  margin-top: 16px;
  text-decoration: none;
  width: 260px;
}`

export const AgendamentoTelefone = styled(AgendamentoSite)`
  a {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
  }
`

export const AgendamentoOu = styled.p`
align-self: end;
margin-bottom: 18px;

&:after {
  content: 'ou';
  display: block;
  font-size: 1.5rem;
  line-height: 26px;
}

@media (max-width: 768px) {
  align-self: center;
}
`

export const Social = styled.div`
  min-width: 168px;
  text-align: center;
  p {
    margin-bottom: 30px;
  }

  div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
  }
`

export const Privacy = styled.div`
  margin-bottom: 32px;
  text-align: center;

  a {
    color: #00987B;
    display: block;
    margin-bottom: 24px;
  }
`

export const FooterLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  a {
    &:after {
      display: inline-block;
      margin-left: 8px;
      content: "|";
    }
    &:last-child:after{
      display: none;
    }
  }
`

export const Logo = styled.img`
  margin-bottom: 46px;
  @media (max-width: 768px) {
    margin-bottom: 54px;
  }
`
