import styled from "styled-components"

export const Container = styled.div`
  box-sizing: border-box;
  padding-left: 3.875rem;
  padding-right: 3.875rem;
  padding-top: 47px;
  padding-bottom: 72px;
  width: 100%;
`

export const Header = styled.h2`
  color: #00987B;
  font-family: 'Inter';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 43px;
  text-align: center;
`

export const GridDiferenciais = styled.div`
  display: grid;
  gap: 78px;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 72px;
  padding-top: 72px;

  @media (max-width: 1400px) {
    overflow-x: scroll;
    overflow-y: visible;
    gap: 42px;
  }
`
