import * as S from './styled';
import { Header } from '../../components/Header';
import { Hero } from './Sections/Hero';
import Footer from '../../components/Footer';
import ComoFunciona from './Sections/ComoFunciona';
import Diferenciais from './Sections/Diferenciais';
import QuemSomos from './Sections/QuemSomos';
import NossasClinicas from './Sections/NossasClinicas';
import { ContactUs } from './Sections/ContactUs';
import { Agendamento } from './Sections/Agendamento';
import Faq from './Sections/Faq';

export function Home() {
  return (
    <>
      <S.HomeContainer>
        <Header>
          <a href="#como-funciona">Como funciona</a>
          <a href="#quem-somos">Quem somos</a>
          <a href="#nossas-clinicas">Nossas clínicas</a>
          <a href="#agendamento-consultas">Agendamento de consultas</a>
        </Header>
        <Hero />
      </S.HomeContainer>
      <Diferenciais />
      <ComoFunciona />
      <QuemSomos />
      <NossasClinicas />
      <S.Wrapper>
        <Agendamento />
        <ContactUs />
      </S.Wrapper>
      <Faq />
      <Footer />
    </>
  );
}
