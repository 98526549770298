import './assets/css/reset.css';
import './assets/css/fonts.css';
import './App.css';
import { ThemeProvider } from './theme/Provider';
import { Home } from './pages/Home';
import { ContactFormProvider } from './contexts/ContactFormDataContext';

function App() {
  return (
    <ThemeProvider>
      <ContactFormProvider>
        <Home />
      </ContactFormProvider>
    </ThemeProvider>
  );
}

export default App;
