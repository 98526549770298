import styled from "styled-components"
import bgCurva from '../../../../assets/img/bg-curva.png'

export const Background = styled.div`
  background-image: url(${bgCurva});
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 100%;
  box-sizing: border-box;
  padding-left: 3.875rem;
  padding-right: 3.875rem;
  margin-bottom: -1px;

  @media (max-width: 450px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`

export const Container = styled.div`
  aspect-ratio: 1242 / 588;
  background: #00987B;
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;

  & > div {
    color: #FFFFFF;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    padding-bottom: 76px;
    padding-left: 61px;
    padding-right: 67px;
    padding-top: 76px;

    h2 {
      font-weight: 700;
      font-size: 32px;
      line-height: 37px;
      margin-bottom: 32px;
    }

    p {
      margin-bottom: 32px;
    }

    ul li {
      list-style-type: disc;
      list-style-position: inside;
      padding-left: 15px;
    }

    strong {
      font-weight: 700;
    }

    @media (max-width: 450px) {
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  @media (max-width: 1280px) {
    aspect-ratio: unset;
    flex-direction: column;
    height: auto;
  }

`
