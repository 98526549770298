import * as S from './styled'
import quemSomos from '../../../../assets/img/quem-somos.png'

export default function QuemSomos () {
  return <S.Background id="quem-somos">
    <S.Container>
      <div>
        <h2>Quem somos</h2>
        <p>
          O cartão Soft é um cartão corporativo do Cartão de TODOS.
        </p>
        <p>
        Desenvolvido exclusivamente para as empresas como benefício a seus colaboradores, o cartão Soft oferece descontos exclusivos em consultas médicas, exames e medicamentos.
        </p>
      </div>
      <img src={quemSomos} alt="" />
    </S.Container>
  </S.Background>
}
