import imagemComoFunciona from '../../../../assets/img/como-funciona.png';
import * as S from './styled';

export default function ComoFunciona() {
  return (
    <S.Container id="como-funciona">
      <S.Content>
        <h2>Como funciona</h2>
        <p>
          <strong>Na Clínica AmorSaúde</strong>: Para garantir o desconto no
          valor das consultas e exames, basta informar ao atendente que você é
          um cliente <strong>Cartão SOFT</strong> e apresentar seu CPF
        </p>
        <br />
        <p>
          <strong>Nas redes de farmácia Droga Raia e Drogasil</strong>
        </p>
        <p>
          Para obter desconto em medicamentos, basta informar o número do CPF no
          balcão da loja.
        </p>
      </S.Content>
      <S.Image src={imagemComoFunciona} />
    </S.Container>
  );
}
