import { styled } from '../../../../theme/Provider';

export const HeroContainer = styled.div`
  width: 100%;
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .pattern-deco {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    min-height: unset;
    object-fit: cover;
    object-position: center;
  }
  @media (min-width: 769px) {
    .pattern-deco {
      min-height: 58rem;
    }
  }
`;
export const ExtendTopImgBg = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 22%;
  background-color: ${({
    theme: {
      root: { colors },
    },
  }) => colors.primary};
`;
export const ExtendBottomImgBg = styled.div`
  position: absolute;
  z-index: -3;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 50.75rem;
  border-radius: 30rem;
  background-color: ${({ theme: { hero } }) => hero.specialBg};
`;

export const HeroContentContainer = styled.div`
  display: flex;
  margin-top: min(5vw, 10rem);
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CTAContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  button {
    width: max(36.5%, 10rem);
    margin-top: min(5vw, 10rem);
  }
  @media (max-width: 768px) {
    text-align: center;
    align-items: center;
  }
  @media (max-width: 480px) {
    button {
      width: 40%;
      order: 2;
    }
  }
`;
export const HeroTitle = styled.h1`
  color: ${({ theme: { hero } }) => hero.title.color};
  font: ${({ theme: { hero } }) => hero.title.font};
  strong {
    font-weight: 700;
  }
  @media (max-width: 480px) {
    width: 80%;
    order: 1;
    margin-top: 1rem;
    font: ${({ theme: { hero } }) => hero.title.fontMobile};
    span.mobile-nowrap {
      white-space: nowrap
    }
  }
`;

export const HeroSubtitle = styled.p`
  margin-top: 1rem;
  color: ${({ theme: { hero } }) => hero.subtitle.color};
  font: ${({ theme: { hero } }) => hero.subtitle.font};
  strong {
    font-weight: 700;
  }
  @media (max-width: 480px) {
    order: 0;
    font: ${({ theme: { hero } }) => hero.subtitle.fontMobile};

  }
`;

export const HeroImgFrame = styled.div`
  display: flex;
  flex: 1.45;
  align-items: flex-start;
  justify-content: flex-end;
  img {
    width: min(100%, 37rem);
    margin-top: min(-2vw, -3rem);
  }
  @media (max-width: 768px) {
    margin-top: min(26vw, 9rem);
  }
`;
