import styled from "styled-components"

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  width: 250px;

  @media (max-width: 450px) {
    width: 180px;
  }
`

export const Icone = styled.div`
  align-items: center;
  aspect-ratio: 1;
  background-color: #00987B;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: -72px;
  width: 120px;
  z-index: 2;
`

export const Content = styled.div`
  border: 1px solid #A1C518;
  border-radius: 40px;
  flex: 1 100%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 80px 19px 29px 19px;
  text-align: center;
`
