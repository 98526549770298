import { styled } from '../../../../theme/Provider';

export const AgendamentoContainer = styled.div`
  width: 46%;
  margin-right: 12%;
  margin-left: 3.875rem;

  @media (max-width: 960px) {
    margin: 0 auto;
  }

  @media (max-width: 450px) {
    width: 80%;
  }
`;

export const ImgContainer = styled.div`
  position: absolute;
  margin-top: -17.75rem;
  margin-left: -3rem;
`;

export const AgendamentoTitle = styled.h1`
  margin-top: 5.875rem;
  color: ${({ theme: { schedule } }) => schedule.title.color};
  font: ${({ theme: { schedule } }) => schedule.title.font};
`;

export const Button = styled.a`
  border: 2px solid #00987B;
  border-radius: 16px;
  color: #00987B;
  display: block;
  margin-top: 16px;
  padding: 16px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  @media (max-width: 450px) {
    border: 0 none;
    font-size: 80%;
    text-decoration: underline;
    text-transform: none;
    padding-left: 0;
    padding-right: 0;
  }
`

export const AgendamentoSubTitle = styled.h1`
  margin-top: 2.75rem;
  color: ${({ theme: { schedule } }) => schedule.subtitle.color};
  font: ${({ theme: { schedule } }) => schedule.subtitle.font};
  span {
    white-space: nowrap;
  }
`;

export const AgendamentoFeatures = styled.ul`
  margin-top: 2.75rem;
  color: ${({ theme: { schedule } }) => schedule.subtitle.color};
  font: ${({ theme: { schedule } }) => schedule.subtitle.font};
  li {
    display: list-item;
    margin-left: 2rem;
    list-style: disc;
  }
`
