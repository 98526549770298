import { ReactNode } from 'react'
import * as S from './styled'

export interface DiferencialContainerProps {
  image: string
  children: ReactNode
}

export default function DiferencialContainer ({image, children}: DiferencialContainerProps) {
  return <S.Container>
    <S.Icone>
      <img src={image} alt="Icone" />
    </S.Icone>
    <S.Content>
      {children}
    </S.Content>
  </S.Container>
}
