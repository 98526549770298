import { styled } from '../../theme/Provider';

export const HeaderContainer = styled.header`
  display: flex;
  width: 100%;
  height: 80%;
  box-sizing: border-box;
  padding: ${({ theme: { header } }) => header.padding};
  border-radius: ${({ theme: { header } }) => header.borderRadius};
  background-color: ${({ theme: { header } }) => header.background};
  @media (max-width: 1080px) {
    padding: 1.25rem;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex: 3 0;
  align-items: center;
  svg {
    width: 5.688rem;
    height: 2.5rem;
  }
`;

export const MenuItemsContainer = styled.div`
  display: flex;
  flex: 7.8;
  align-items: center;
  justify-content: flex-end;
  & a {
    flex: 0.7 0 8rem;
    color: ${({ theme: { header } }) => header.color};
    font: ${({ theme: { header } }) => header.font};
    text-decoration: none;
    white-space: nowrap;
  }
  @media (max-width: 1080px) {
    display: none;
  }
`;

export const ButtonContainer = styled.div`
  flex: 3 0 14rem;
  a {
    display: flex;
    justify-content: flex-end;
  }
  @media (max-width: 1080px) {
    display: none;
  }
`;

export const MobileIconContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;
  @media(min-width: 1081px) {
    display: none;
  }
`

export const Button = styled.button``;
