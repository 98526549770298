
import * as S from './styled'
import softLogo from '../../assets/img/logo-soft.png'
import gt7Logo from '../../assets/img/logo-gt7.png'

export default function Footer () {
  return <S.Container>
    <S.Contact>
      <S.SoftLogo src={softLogo} alt="Logo soft" />
      <S.Agendamento>

      <S.AgendamentoTelefone>
        <p>Agende via telefone</p>
        <a href="tel: 40035745">4003 5745</a>
      </S.AgendamentoTelefone>

      </S.Agendamento>
    </S.Contact>
    <S.Privacy>
      <S.FooterLinkContainer>
        <a href="https://cartaodetodos.com.br/politica-de-privacidade/" target="_blank" rel="noreferrer">Política de Privacidade</a>
        <a download="Contrato_Adesão_SOFT_lp.pdf" href={`${process.env.PUBLIC_URL}/documents/Contrato_Adesão_SOFT_lp.pdf`}>Termo de Adesão</a>
      </S.FooterLinkContainer>
      <p>TODOS Empreendimentos Ltda. 04.644.515/0001-85 © 2020.</p>
    </S.Privacy>
    <S.Logo src={gt7Logo} alt="Logo da GT7" />
  </S.Container>
}
