import styled from 'styled-components'

export const Container = styled.div`
  background-color: #57d6d9;
  padding-bottom: 219px;
  padding-top: 62px;
  position: relative;

  & > div {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    font-family: 'Ubuntu';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    margin: 0 auto;
    line-height: 37px;
    max-width: 869px;
    text-align: center;

    @media (max-width: 930px) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  @media (max-width: 960px) {
    padding-bottom: 229px;
    margin-bottom: 190px;
  }

  @media (max-width: 360px) {
    margin-bottom: 95px;
  }
`

export const LogoAmorSaude = styled.img`
  margin-bottom: 39px;
  margin-left: auto;
  margin-right: auto;
`

export const Title = styled.h2`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 32px;

  small {
    font-size: 70%;
  }
`

export const Text = styled.div`
  margin: 0 auto;
  max-width: 646px;
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 62px;
  margin-bottom: 43px;
  margin-top: 62px;

  @media (max-width: 930px) {
    grid-template-columns: 1fr;
    margin-bottom: 41px;
    gap: 24px;
  }
`

export const Holder = styled.div`
  align-items: center;
  border: 2px solid #FFFFFF;
  border-radius: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-size: 22px;
  gap: 20px;
  justify-content: center;
  line-height: 24px;
  max-width: 100%;
  padding: 22px 48px;
  text-align: left;

  & > div:first-child {
    font-size: 62px;
    font-weight: 700;
    line-height: 62px;
  }

  ul {
    list-style-type: disc;
    list-style-position: outside;
    margin-left: 16px;
  }

  @media (max-width: 455px) {
    padding: 22px 15px;

    ul {
      margin-left: 48px;
      list-style-position: outside;
      width: calc(100% - 48px);

      li {
        margin-bottom: 8px;
      }
    }
  }
`

export const HolderLarge = styled(Holder)`
  padding-top: 45px;
  padding-bottom: 45px;
  margin-bottom: 48px;

  &>ul {
    align-items: center;
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  @media (max-width: 960px) {
    margin-bottom: 0;
  }

  @media (max-width: 455px) {
    flex-direction: column;
    gap: 0;
    padding: 22px 40px;

    & > ul {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 16px;
    }
  }
`

export const Button = styled.a`
  align-items: center;
  background: #FFFFFF;
  border-radius: 8px;
  color: #54C4CF;
  display: flex;
  font-family: 'Ubuntu';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: 64px;
  justify-content: center;
  line-height: 32px;
  margin: 0 auto;
  text-decoration: none;
  width: 219px;

  @media (max-width: 960px) {
    display: none;
  }
`

export const Boneco = styled.div`
  position: absolute;
  bottom: -57px;
  left: 18px;

  @media (max-width: 960px) {
    bottom: -190px;
    left: 0;
    right: 0;

    & > img {
      margin: 0 auto;
      max-width: 100%;
      height: auto;
      display: block;
    }
  }

  @media (max-width: 360px) {
    bottom: -95px;
  }
`
