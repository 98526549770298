import baseStyled, {
  ThemedStyledInterface,
  ThemeProvider as BaseThemeProvider,
} from 'styled-components';
import { softSodexoTheme } from './root';

export const styled = baseStyled as ThemedStyledInterface<ITheme>;

export type ITheme = typeof softSodexoTheme;

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  return (
    <BaseThemeProvider theme={softSodexoTheme}> {children}</BaseThemeProvider>
  );
}
