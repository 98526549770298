import * as S from './styled';
import bgPattern from '../../../../assets/imgs/hero-bg.png';
import bgMobilePattern from '../../../../assets/imgs/hero-bg-mobile.png';
import heroImgFrame from '../../../../assets/imgs/hero-img-frame.png';
import { Button } from '../../../../components/Button';

export function Hero() {
  return (
    <S.HeroContainer>
      <S.BackgroundContainer>
        <picture>
          <source srcSet={bgPattern} media="(min-width: 769px)" />
          <source srcSet={bgMobilePattern} />
          <img className="pattern-deco" src={bgPattern} alt="" aria-hidden />
        </picture>
      </S.BackgroundContainer>
      <S.HeroContentContainer>
        <S.CTAContainer>
          <S.HeroTitle>
            <strong>Cartão Soft</strong> oferece <span className='mobile-nowrap'>
            desconto em</span> consultas médicas, exames e farmácias.
          </S.HeroTitle>
          <Button variant="primary" onClick={() => {window.location.href = '#quem-somos'}}>
            Saiba mais
          </Button>
        </S.CTAContainer>
        <S.HeroImgFrame>
          <img src={heroImgFrame} alt="" />
        </S.HeroImgFrame>
      </S.HeroContentContainer>
    </S.HeroContainer>
  );
}
