import { styled } from "../../theme/Provider";

export const HomeContainer = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: ${(props) => props.theme.main.paddingDesktop};
  overflow-x: hidden;
  @media (max-width: 768px) {
    padding: ${(props) => props.theme.main.paddingMobile};
    header {
      width: clamp(21rem, 103%, 46rem);
      margin: auto;
      margin-top: -0.5rem;
      margin-left: -0.5rem;
    }
  }
`
export const Wrapper = styled.div`
  display: flex;
  padding-bottom: 62px;
  width: 100%;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`
