import * as S from './styled';

export function Agendamento() {
  return (
    <S.AgendamentoContainer>
      <S.AgendamentoTitle>Agendamento de consultas</S.AgendamentoTitle>
      <S.AgendamentoSubTitle>
        Para agendar sua consulta ligue 4003-5745.
      </S.AgendamentoSubTitle>
    </S.AgendamentoContainer>
  )
}
