import * as S from './styled';
import { ReactComponent as Logo} from '../../assets/svgs/logo.svg';
import { ReactComponent as MobileIcon} from '../../assets/svgs/mobile-header-btn.svg';
import { Button } from '../Button';

type HeaderProps = {
  children: React.ReactNode;
}

export function Header({ children }: HeaderProps) {
  return (
    <S.HeaderContainer>
      <S.LogoContainer>
        <Logo role="img" aria-label="logo-cartao-soft" />
      </S.LogoContainer>
      <S.MenuItemsContainer>
        {children}
        <S.ButtonContainer>
        <a href="#agendamento-consultas">
            <Button variant="secondary" onClick={() => ({})}>Fale Conosco</Button>
        </a>
        </S.ButtonContainer>
      </S.MenuItemsContainer>
      <S.MobileIconContainer>
        <MobileIcon />
      </S.MobileIconContainer>
    </S.HeaderContainer>
  );
}
